import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
// import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import LandingPage from '../LandingPageMaterial/Layout/LandingPage'
import GridContainer from "components/material-kit-pro-react/components/Grid/GridContainer.js";
import GridItem from "components/material-kit-pro-react/components/Grid/GridItem.js";
import Parallax from "components/material-kit-pro-react/components/Parallax/Parallax.js";
import SectionTemplate from 'LandingPageMaterial/Views/Sections/SectionTemplate'
import sectionStyle from "LandingPageMaterial/Views/Sections/sectionNoticiasStyle";
import AdvertisingCard from 'components/Core/Card/AdvertisingCard';

// const useStyles = makeStyles(sectionStyle);

const NewDiv = styled("div")(({ theme }) => {
    return { 
        ...sectionStyle.container
     }
  });

  const NewH1 = styled("h1")(({ theme }) => {
    return { 
        ...sectionStyle.title
     }
  });

export default function Publications() {
//   const classes = useStyles();

  const data = useStaticQuery(
    graphql`
    query{
        allStrapiPublicidade(
            sort: {order: DESC, fields: fecha_publicacion}
            filter: {categoria_publicacion: {identificador_categoria: {eq: "2"}}}
          ) {
            edges {
              node {
                id
                titulo_publicidad
                fecha_publicacion
                imagen_principal {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(quality: 95, layout: FULL_WIDTH)
                    }
                  }
                }
                imagen_alterna {
                  localFile {
                    childImageSharp {
                      fluid {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
          allStrapiAssetsMedia(filter: {codigo: {eq: "COMUNICADOS_BACKGROUND"}}) {
            edges {
              node {
                archivo {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1700, quality: 95) {
                        base64
                        tracedSVG
                        srcWebp
                        srcSetWebp
                        originalImg
                        originalName
                      }
                    }
                  }
                }
              }
            }
          }
    }
  `
  )

  console.log(data.allStrapiAssetsMedia.edges[0].node.archivo.localFile.childImageSharp.fluid.srcWebp)


  const advertising = data.allStrapiPublicidade.edges;
  const backgroundImage = data.allStrapiAssetsMedia.edges[0].node.archivo.localFile.childImageSharp.fluid.srcWebp
  
  return (
    <LandingPage noLinks>
      <Parallax small image={backgroundImage}>
          <NewDiv 
          className={sectionStyle.container}
          >
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12} 
                className={sectionStyle.textCenter}
                >
                    <NewH1></NewH1>
                </GridItem>
              </GridContainer>
          </NewDiv>
      </Parallax>
      <SectionTemplate>
      <GridContainer>
      {
        advertising.map(({ node }, index) => (
          <AdvertisingCard
            node={node}
            index={index}
            key={`${index}_ac`}
            mdSize={4}
          />
        ))
      }
      </GridContainer>
      </SectionTemplate>
    </LandingPage>
  )
}